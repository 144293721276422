import "./style.css"

import React, {useEffect, useState} from "react";

function Footer({
                    curUserInfo = {},
                    setPath=(path)=>{},
                    path="",
}) {

    const [versionInfo, setVersionInfo] = useState("")

    useEffect(()=>{
        fetch("/version.info")
            .then(it => it.text())
            .then(it => it.split(/\n/g))
            .then(it => {
                const vi = {}
                it.map( l => l.split(":"))
                    .forEach(l => {
                        vi[l[0]] = l.slice(1).join(":")
                    })
                return vi
            })
            .then(it => setVersionInfo(it))
    }, [])

    return (<div className={"versionInfo"}>{ Object.keys(versionInfo).map(k => (
        <p className={k}><i>{k}</i><b>{versionInfo[k]}</b></p>
    )) }</div>);
}

export default Footer;
