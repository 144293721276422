import "@blueprintjs/table/lib/css/table.css";

import "./style.css";

import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";

import {
    AnchorButton,
    Button, Card, ControlGroup, FormGroup,
    HTMLTable,
    InputGroup,
    Intent, NonIdealState, Spinner,
    Tag
} from "@blueprintjs/core";

import DateInput, {formatDateLocal} from "../../core/DateInput";


// import Storages from "./Storages";
import {getOrCreate} from "../../core/fncs";
import {Confirm, Error, ErrorNotAuth} from "../../core/Confirm";
import Editor from "./Editor";
// import Categories from "./Categories";
// import Keywords from "./Keywords";
import SortButtons from "./SortButtons";
import AccLoader from "./AccLoader";
import PayProducts from "./PayProducts";

const emptyAcc = {agent: {}}

let aborterFetchAccs // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!

const fetchAccs = (setter, period, stateSetter)=> {
    if(!(period && (period.from || period.to))) return;

    stateSetter({type: "wait", description: "Подготавливается запрос"}) // setter( [] );

    if(aborterFetchAccs){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        aborterFetchAccs.abort() // отменить выполняющийся запрос
    }
    aborterFetchAccs = new AbortController(); // на каждый запрос нужен свой абортарий

    const urlpars = [];
    if(period && period.from && period.from != "NaN"){
        try {
            urlpars.push("from="+period.from.getTime())
        }catch (e) {
            console.log(e)
        }
    }
    if(period && period.to && period.to != "NaN"){
        try{
            urlpars.push("to="+period.to.getTime())
        }catch (e) {
            console.log(e)
        }
    }


    window.fetch(
        "/payments?"+urlpars.join("&")
        ,{
            signal: aborterFetchAccs.signal // привязка запроса к абортарию
        })
        .then(it => {
            stateSetter({type: "wait", description: "Передаётся перечень рассчётов"})
            return it.json()
        })
        .then( it => {
            stateSetter({type: "wait", description: "Подготавливается перечень рассчётов"})
            setter(
                it.map((t)=>{
                    t.keywords = t.keywords||[];
                    return t;
                })
            );
            aborterFetchAccs = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
            stateSetter({type: "ok"})
        })
        .catch( err => {
            stateSetter({type: "error", description: err})
            console.log(err);
            aborterFetchAccs = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
        })
}

export default function Accs({
                       curUserInfo={state: false},
                       setPath=(path)=>{},
                       path = ""
                   }) {

    const [state, setState] = useState({type: ""});
    const [curAccId, setCurAccId] = useState("");

    // let [builderFor, setBuilderFor] = useState("");
    const [editorFor, setEditorFor] = useState("");

    const [allAccs, setAllAccs] = useState([]);
    const [accs, setAccs] = useState([]);
    const [curPeriod, setCurPeriod] = useState({});

    // const [selectedCategory, setSelectedCategory] = useState("");
    // const [categories, setCategories] = useState([]);
    // const [keywordss, setKeywordss] = useState([]);

    const [search, setSearch] = useState("");
    const [orderBy, setOrderBy] = useState({k:"datetime", o:-1});

    useEffect(() => {
        const curday   =  new Date()
        const fromday =  new Date(curday.getFullYear(), curday.getMonth(), 1)
        const today = curday
        today.setDate(today.getDate()+1)
        setCurPeriod({from: fromday, to: today})
    }, []);

    /*
    useEffect(() => {
        const sp = (new URL(document.location.origin + path)).searchParams

        const perioFrom = sp.get("from")
        const perioTo = sp.get("to")
        const perioFromDat = perioFrom ? new Date(perioFrom) : null
        const perioToDat = perioTo ? new Date(perioTo) : null
        if((curPeriod || {}).from != perioFromDat || (curPeriod || {}).to != perioToDat)
            setCurPeriod({from: perioFromDat, to: perioToDat});

        const edt = sp.get("edt")
        if (editorFor != edt) setEditorFor(edt);

        const aid = sp.get("id")
        if (curAccId != aid) setCurAccId(aid);

    }, [path]);
    */

    useEffect(() => {
        if(curUserInfo && curUserInfo.sub && curPeriod && (curPeriod.from || curPeriod.to)) {

            if("" != editorFor)         setEditorFor("")

            fetchAccs((accs)=>{
                // setCurAccId("")
                setEditorFor("")
                setAllAccs(accs)
            }, curPeriod, setState);
        }
    }, [curPeriod, curUserInfo]);

    useEffect(() => {
        if(curUserInfo && curUserInfo.sub && allAccs) {
            filter()

            // setCurAccId("")
            setEditorFor("")

            // {
            //     const ks = allAccs.map((w) => (w.category)).filter(k=>((k||"").trim().length))
            //     setCategories( ks.filter((k, i) => (i == ks.indexOf(k))) );
            // }
            //
            // {
            //     const ks = allAccs.flatMap((w) => (w.keywords||[])).filter(k=>((k||"").trim().length))
            //     setKeywordss(ks.filter((k, i) => (i == ks.indexOf(k))));
            // }
            //
        }
    }, [allAccs, curUserInfo]);

    useEffect(() => {
        filter()
    }, [/* selectedCategory,*/ search]);

    useEffect(() => {
        setAccs(
            JSON.parse(JSON.stringify( sorting(accs) ))
        )
    }, [orderBy]);

    /*
    useEffect(() => {
        if(curPeriod && (curPeriod.from || curPeriod.to) ) {
            const sp = (new URL(document.location.origin + path)).searchParams
            const arr = []

            if (curPeriod && curPeriod.from && curPeriod.from.getTime) arr.push("from=" + curPeriod.from.getTime())
            if (curPeriod && curPeriod.to && curPeriod.to.getTime) arr.push("to=" + curPeriod.to.getTime())

            if (editorFor) arr.push("edt=" + editorFor)

            if (curAccId) arr.push("id=" + curAccId)

            setPath("/accs?" + arr.join("&"))
        }
    }, [curPeriod, curAccId, editorFor]);
    */

    // useEffect(()=>{
    //     if(curAccId && allAccs && allAccs.length) showBuilder(allAccs.filter(it=>(builderFor == it.code))[0])
    // }, [curAccId, allAccs])

    useEffect(()=>{
        if( editorFor && allAccs && allAccs.length && curUserInfo && curUserInfo.state )
            showFormForEditAcc(allAccs.filter(it=> (editorFor == it.id))[0])
    }, [editorFor, allAccs, curUserInfo])

    const deleteAcc = function (acc, setter) {
        if( curUserInfo && curUserInfo.state ) {
            if (curUserInfo && curUserInfo.sub) {

                ReactDOM.createRoot(getOrCreate()).render((<Confirm
                    isOpen={true}
                    cancelButtonText="Отмена"
                    confirmButtonText="Удалить"
                    icon="trash"
                    intent={Intent.DANGER}

                    onConfirm={() => {
                        fetch("/payments", {method: "POST", body: JSON.stringify({type: "paymentDelete", data: acc})})
                            .then(it => {
                                return it.json()
                            })
                            .then(it => {
                                if (it.id === acc.id) {
                                    setter(accs.filter(it => {
                                        return it.id !== acc.id
                                    }))
                                    // setCurTmpl({});
                                } else {
                                    // window.alert(it.code+": "+it.title+"\r\n"+it.description)
                                    ReactDOM.createRoot(getOrCreate())
                                        .render((<Error><b>{it.code}</b><p>{it.title}</p>
                                            <code>{it.description}</code></Error>))
                                }
                            });
                    }}
                >
                    <p>
                        Удалить рассчёт «{acc.id}»?
                    </p>
                </Confirm>))

            } else {
                ReactDOM.createRoot(getOrCreate()).render((<ErrorNotAuth/>))
            }
        }
    }

    const saveAcc = function (reqData, callback) {
        if( curUserInfo && curUserInfo.sub ) {

            fetch("/payments", {method: "POST", body: JSON.stringify(reqData)})
                .then( it => { return it.json() })
                .then( it => {
                    if( it.type === "err" ){
                        // alert( it.code +": "+ it.title + "\r\n\r\n" + it.description)
                        ReactDOM.createRoot( getOrCreate() )
                            .render((<Error><b>{it.code}</b><p>{it.title}</p><code>{it.description}</code></Error>))

                    }else {
                        fetchAccs( (arr)=>{
                            setAllAccs(arr)
                            // setCurAccId(it.id);
                            callback && callback(it);
                        }, curPeriod, setState);
                        setEditorFor("");
                    }
                });
        }else{
            ReactDOM.createRoot( getOrCreate() ).render((<ErrorNotAuth/>))
        }
    }

    const showFormForEditAcc = function (acc) {
        if(curUserInfo && curUserInfo.state) {
            if (curUserInfo && curUserInfo.sub) {
                ReactDOM.createRoot(getOrCreate()).render(
                    (<Editor
                        payment={acc}
                        onSave={(accData)=>{
                            saveAcc( {type: (acc == emptyAcc ? "paymentAdd" : "paymentUpdate"), data: accData}, (a)=>{ setCurAccId(a.id) } )
                        }}
                        onClose={() => {
                            setEditorFor("")
                            // setCurAccId("")
                        }}
                        setPath={setPath}
                        path={path}
                    />))
            } else {
                ReactDOM.createRoot(getOrCreate()).render((<ErrorNotAuth/>))
            }
        }
    }

    const showFormForLoadAcc = function () {
        if( curUserInfo && curUserInfo.sub ) {
            ReactDOM.createRoot( getOrCreate() ).render(
                (<AccLoader
                    afterLoad={(rez)=>{
                        // setEditorFor("")
                        fetchAccs( (arr)=>{
                            setAllAccs(arr)
                            if(rez && rez[0] && rez[0].payment && rez[0].payment.id) setCurAccId(rez[0].payment.id)
                            else setCurAccId("")
                        }, curPeriod, setState );
                    }}
                />))
        }else{
            ReactDOM.createRoot( getOrCreate() ).render( (<Confirm intent={Intent.DANGER}>Вы не авторизованы</Confirm>))
        }
    }

    const filter = function(){ // Поиск
        const filteredAccs = allAccs.filter(it => {
                if(search==="") return 1;
                const text = (it.datetime
                    + " " + it.sum
                    + " " + it.qty
                    + " " + (it.agent.code|| "")+ " " + (it.agent.title|| "")
                    + " " + it.description
                    // + " " + it.keywords
                    // + " " + it.category
                ).trim().toUpperCase();
                if(text.length>0){
                    return search
                        .split(/;\s*/)
                        .filter(it => (it.trim().length))
                        .filter((s)=>( text.indexOf(s.trim().toUpperCase())+1 ) )
                        .length;
                }else{
                    return 0;
                }
            })
            /* .filter( it => {
                return (!selectedCategory || (it.category === selectedCategory))
            }) */
        ;
        setAccs( sorting(filteredAccs) )
        setEditorFor("")
        // setCurAccId("")
    }

    const sorting = function (items){
        return items.sort( (a, b)=> {

            const sortPath = (orderBy.k || "").split(".")

            let o_a = a
            let o_b = b

            for( let index=0; sortPath.length > index; index++ ){
                o_a = o_a[sortPath[index]]
                o_b = o_b[sortPath[index]]
            }

            o_a = o_a||""
            o_b = o_b||""

            if ( o_a > o_b ) {
                return orderBy.o
            } else {
                return -1*(orderBy.o)
            }
        })
    }


    return (<>
      <ControlGroup
          fill={true}
          className={"upline"}
      >

          <FormGroup
              label="Период"
              inline={true}
              className={"period-selector"}
          >
              <DateInput
                  value={curPeriod.from}
                  onChange={(str, dat)=>{
                      setCurPeriod( Object.assign({}, curPeriod, {from: dat} ) )
                  }}
              />
              <DateInput
                  value={curPeriod.to}
                  onChange={(str, dat)=>{
                      setCurPeriod( Object.assign({}, curPeriod, {to: dat} ) )
                  }}
              />
          </FormGroup>

          <InputGroup
              id="inpSearch"
              fill={false}
              type="search"
              placeholder="Поиск…"
              value={search}
              onChange={(e)=>{
                  setSearch(e.target.value)
              }}
          />
      </ControlGroup>

      <div className={"main-content"}>

          {/*
              <Categories categories={categories} selectedCategory={selectedCategory} chCaterory={setSelectedCategory} />
          */}

          <Card
              compact={true}
              className={"accs-list"}
          >
              {   !( curUserInfo && curUserInfo.sub)
                      ? (<NonIdealState
                          icon={"log-in"}
                          title={ state.title || "Вы не авторизованы"}
                          description={state.description || "Для работы необходимо авторизоваться"}
                          action={<AnchorButton icon={"log-in"} href="/login">Авторизация</AnchorButton>}
                      />)
                      : (state.type == "wait"
                              ? (<NonIdealState
                                    icon={<Spinner/>}
                                    title={ state.title || "Обождите, процесс в процессе"}
                                    description={state.description || "Видимо очень много данных"}
                              />)
                          : (
                                  state.type == "error"
                                      ? (<NonIdealState
                                          icon={"error"}
                                          title={ state.title || "Ошибочка"}
                                          description={state.description || "Что-то пошло не так"}
                                      />)
                                      : (
                                          accs.length == 0
                                              ? (<NonIdealState
                                                  icon={"search-template"}
                                                  title={ state.title || "Ни чего не найдено"}
                                                  description={state.description || "Рассчётов удовлетворяющих заданным критериям не обнаружено"}
                                                  action={<><Button id="btnToPaymentUpload"
                                                                    icon={"cloud-upload"}
                                                                  onClick={() => {
                                                                      showFormForLoadAcc()
                                                                  }}
                                                          >Загрузить чек</Button>

                                                          <Button  id="btnToAddPayment"
                                                                   icon="plus"
                                                                  onClick={(e) => {
                                                                      e.stopPropagation()
                                                                      e.preventDefault()
                                                                      showFormForEditAcc(emptyAcc)
                                                                  }}
                                                          >Добавить</Button>
                                                         </>}
                                              />)
                                              : (
                                                  <HTMLTable
                                                      bordered={true}
                                                      striped={true}
                                                      interactive={true}
                                                  >
                                                      <thead>
                                                      <tr className="Accs">
                                                          <th className="accs-datetime">
                                                              дата-время
                                                              <SortButtons
                                                                  code={"datetime"}
                                                                  current={orderBy} chOrderBy={setOrderBy}
                                                              />
                                                          </th>
                                                          <th className="accs-sum">
                                                              Сумма
                                                              <SortButtons
                                                                  code={"sum"}
                                                                  current={orderBy} chOrderBy={setOrderBy}
                                                              />
                                                          </th>
                                                          <th className="accs-qty">
                                                              Кол-во
                                                              <SortButtons
                                                                  code={"qty"}
                                                                  current={orderBy} chOrderBy={setOrderBy}
                                                              />
                                                          </th>
                                                          <th className="accs-agent">
                                                              Кто-где
                                                              <SortButtons
                                                                  code={"agent.code"}
                                                                  current={orderBy} chOrderBy={setOrderBy}
                                                              />
                                                          </th>
                                                          <th className="accs-agent">
                                                              Описание
                                                          </th>
                                                          {
                                                              curUserInfo.sub
                                                                  ? <th className="accs-actions">
                                                                      <Button
                                                                          id="btnToPaymentUpload"
                                                                          icon={"cloud-upload"}
                                                                          onClick={() => {
                                                                              showFormForLoadAcc()
                                                                          }}
                                                                      >Загрузить чек</Button>
                                                                      <Button
                                                                          icon={"plus"}
                                                                      onClick={() => {
                                                                          showFormForEditAcc(emptyAcc)
                                                                      }}
                                                                      >Добавить</Button>
                                                                  </th>
                                                                  : <></>
                                                          }
                                                      </tr>
                                                      </thead>

                                                      <tbody>
                                                      {
                                                          accs.map((t) => (<>
                                                              <tr className={ (curAccId == t.id) ? "active" : "" }
                                                                  onClick={() => {
                                                                      setCurAccId( (curAccId == t.id) ? "" : t.id)
                                                                  }}
                                                              >
                                                                  <td
                                                                      className="accs-datetime"
                                                                  >{ formatDateLocal(t.datetime, true) }</td>
                                                                  <td
                                                                      className="accs-sum"
                                                                  >{t.sum}</td>
                                                                  <td
                                                                      className="accs-qty"
                                                                  >{t.qty}</td>
                                                                  <td
                                                                      className="accs-agent"
                                                                  >{t.agent.code}</td>
                                                                  <td
                                                                      className="accs-description"
                                                                  >{t.description}</td>

                                                                  {curUserInfo.sub
                                                                      ? <td
                                                                          className="accs-actions"
                                                                      >
                                                                          <Button minimal={true} text="Удалить"
                                                                                  icon="delete"
                                                                                  onClick={(e) => {
                                                                                      e.stopPropagation()
                                                                                      e.preventDefault()
                                                                                      deleteAcc(t, setAccs)
                                                                                  }}
                                                                          />
                                                                          <Button minimal={true} text="Изменить" icon="edit"
                                                                                  onClick={(e) => {
                                                                                      e.stopPropagation()
                                                                                      e.preventDefault()
                                                                                      setEditorFor(t.id)
                                                                                  }}
                                                                          />
                                                                      </td>
                                                                      : <></>
                                                                  }

                                                              </tr>
                                                                  { (curAccId == t.id)
                                                                      ? <tr className={"details"}>
                                                                          <td colSpan={(5+(curUserInfo.sub ? 1 : 0))}>
                                                                              <PayProducts curUserInfo={curUserInfo} path={path} setPath={setPath} accId={curAccId} />
                                                                          </td>
                                                                        </tr>
                                                                      : <></>
                                                                  }
                                                              </>
                                                          ))
                                                      }
                                                      </tbody>
                                                  </HTMLTable>
                                              )
                                      )
                              )
                      )
              }
          </Card>

          {/*
              <Keywords keywords={keywordss} search={search} chSearch={setSearch}/>
          */}

      </div>

  </>);
}

