
import { MenuItem } from "@blueprintjs/core";
import {MultiSelect} from "@blueprintjs/select";
import {useEffect, useState} from "react";

let aborterFetchItems // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!
const fetchItems = (setter)=> {
    if(aborterFetchItems){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        aborterFetchItems.abort() // отменить выполняющийся запрос
    }
    aborterFetchItems = new AbortController(); // на каждый запрос нужен свой абортарий

    window.fetch(
        "/keywords"
        ,{
            signal: aborterFetchItems.signal // привязка запроса к абортарию
        })
        .then(it => it.json() )
        .then(it => it.sort((a,b)=>( a.code > b.code )) )
        .then( it => {
            setter(it);
            aborterFetchItems = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
        })
        .catch( err => {
            console.log(err);
            aborterFetchItems = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
        })
}


export default function KeywordsSuggestionator({
    currentItems = [],
    onChange = (itms)=>{}
                                        }) {
    const [items, setItems] = useState([])
    const [selectedItems, setSelectedItems] = useState(currentItems)

    useEffect(() => {
        if (selectedItemsIsNotEq())
            setSelectedItems( items.filter(a => (currentItems.filter(b => itemsIsEqual(a, b)).length > 0)) )
    }, [currentItems, items]);
    useEffect(() => {
        fetchItems(setItems)
    }, []);

    useEffect(() => {
        console.log("useEffect selectedItems: ", selectedItems, currentItems)
        if (selectedItemsIsNotEq()) onChange(selectedItems)
    }, [selectedItems]);

    const itemsIsEqual = (a, b) => (a && b && ((a.code || "").toLowerCase() === (b.code || "").toLowerCase()))

    const selectedItemsIsNotEq = () =>{
        return !(selectedItems && currentItems && (
            (selectedItems || []).sort((a, b) => ((a && b && a.code > b.code) ? 1 : 0)).join("; ") ==
            (currentItems || []).sort((a, b) => ((a && b && a.code > b.code) ? 1 : 0)).join("; ")
        ))
    }
    return (
                <MultiSelect
                    items={items}
                    createNewItemFromQuery={ (query)=>({code: query, title: query}) }
                    createNewItemRenderer={(query, active, handleClick) => (
                                                <MenuItem
                                                    icon="add"
                                                    text={`Добавить "${query}"`}
                                                    roleStructure="listoption"
                                                    active={active}
                                                    onClick={handleClick}
                                                    shouldDismissPopover={false}
                                                />
                                          )}
                    tagRenderer={(itm) => (itm.code)}
                    tagInputProps={{onRemove: (_, index)=>{
                            setSelectedItems( selectedItems.filter( (it, i) => i!=index ) )
                        } }}
                    itemPredicate={ (query, item, _index, exactMatch) => {
                        const normalizedTitle = (item.title||"").toLowerCase();
                        const normalizedQuery = query.toLowerCase();
                        if (exactMatch) {
                            return normalizedTitle === normalizedQuery;
                        }
                        else {
                            return `${item.code}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
                        }
                    } }
                    itemRenderer={ (item, props) => (
                            <MenuItem
                                text={item.code}
                                selected={ selectedItems && ( selectedItems.filter( it => itemsIsEqual(it, item) ).length > 0 ) }
                                active={ selectedItems && ( selectedItems.filter( it => itemsIsEqual(it, item) ).length > 0 ) }
                                disabled={props.modifiers.disabled}
                                onClick={props.handleClick}
                                roleStructure="listoption"
                            />
                        )
                    }
                    noResults={<MenuItem disabled={true} text="Ни чего не найдено" roleStructure="listoption" />}

                    selectedItems={selectedItems}
                    onItemSelect={ (item, e) => {
                        console.log("keywords onItemSelect: ", item)

                        if(selectedItems.filter( it => itemsIsEqual(it, item) ).length){
                            setSelectedItems( selectedItems.filter( it => !itemsIsEqual(it, item) ) )
                        }else{
                            selectedItems.push(item)
                            setSelectedItems( selectedItems.slice(0) )
                        }

                    } }
                    onClear={()=>{setSelectedItems([])}}
                    itemsEqual={itemsIsEqual}
                    scrollToActiveItem={true}
                    closeOnSelect={true}
                    openOnKeyDown={false}
                    resetOnQuery={true}
                    resetOnSelect={true}
                    createNewItemPosition={"last"}
                    placeholder={"Поиск…"}
                    inputProps = {{value: currentItems.map(it => it.code).join("; ")}}
                    popoverProps = {{matchTargetWidth: true}}
                />
    )
}
