import {Card, Tag} from "@blueprintjs/core";

import React, {useEffect, useState} from "react";

function Keywords({
                       keywords={},
                       search = "",
                       chSearch=(search)=>{},
                   }) {
    const [keys, setKeys] = useState([])

    useEffect(() => {
        if(search) setKeys(search.split(/;+\s*/))
    }, []);

    useEffect(() => {
        chSearch( keys.filter((it)=>(it.trim().length)).join("; ") )
    }, [keys]);

    const removeHandler = (s)=>{
        setKeys(
            JSON.parse(JSON.stringify(
                keys
                    .filter((it)=>( it.trim() != s.trim() ))
                ))
        )
    }

    const addHandler = (s)=>{
        if(s && s.length) {
            if( 0<=keys.indexOf(s) ){
                removeHandler(s)
            }else{
                keys.push(s)
                setKeys(JSON.parse(JSON.stringify(keys)))
            }
        }
    }

    return (<Card
                className={"keywords-list"}
                compact={true}
            >
                <div className="keywords-pos">
                    <h5>Ключевые слова</h5>
                    <div className={"pps-keywords"}>
                        {
                            (keywords || []).map(s => (
                                <Tag
                                    interactive={true}
                                    active={0 <= keys.indexOf(s)}
                                    onRemove={0 <= keys.indexOf(s) && (() => {
                                        removeHandler(s)
                                    })}
                                    onClick={() => {
                                        addHandler(s)
                                    }}
                                >{s}</Tag>
                            ))
                        }
                    </div>
                </div>
            </Card>
    );
}

export default Keywords;
