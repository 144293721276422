import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import './style.css';

import React, {useEffect, useState} from "react";

import Markdown from "react-markdown";

import remarkGfm from 'remark-gfm';

import LinkMan from "./LinkMan";
import {Card, CardList, Icon} from "@blueprintjs/core";

function childrenToID(children){
    return ((typeof children == "string") ? children : (
        (children.length == 0) ? children[0] : JSON.stringify(children)
    )).replaceAll(/[^a-z0-9а-я]/ig, "_").replaceAll(/(^__)|(__$)/g, "")
}

function Man({
               curUserInfo={},
               setPath=(path)=>{},
               path = ""
}) {
    const [content, setContent] = useState("")

    const [toc, setToc] = useState([])

    useEffect(() => {
      fetch( ("/mans/" == path ? path+"index.md" : path)+"?xhr", { headers: {"X-Requested-With": "fetch"} } )
          .then( it=> it.text() )
          .then( it => setContent(it) )
    }, [path]);

    useEffect(() => {
        fetch("/manstoc")
            .then( it => it.json())
            .then( it => setToc(it))
    }, []);

    return (<div className={"man"}>

        <CardList className={"toc"}>
            {
                Object.keys(toc||{}).map(k=>(
                    <Card
                        selected={(k == path || k == path+"/index.md" || k == path+"index.md")}
                        interactive={true}
                        style={{justifyContent: "space-between"}}
                        onClick={()=>{ setPath(k) }}
                    >
                        <span>{toc[k]}</span>
                        <Icon icon={"chevron-right"} />
                    </Card>
                ))
            }
        </CardList>


        <div className={"md"}>
            <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                    a: function HandledLink(props) {
                        return (<LinkMan setPath={setPath} href={props.href}>{props.children}</LinkMan>)
                    },
                    h1: function HandledH1(props) {
                        return (<h1 id={childrenToID(props.children)}>{props.children}</h1>)
                    },
                    h2: function HandledH1(props) {
                        return (<h2 id={childrenToID(props.children)}>{props.children}</h2>)
                    },
                    h3: function HandledH1(props) {
                        return (<h3 id={childrenToID(props.children)}>{props.children}</h3>)
                    },
                }}
            >{content}</Markdown>
        </div>
    </div>);
}

export default Man;
