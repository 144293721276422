import {Alert, AnchorButton, Intent} from "@blueprintjs/core";
import React, {useState} from "react";

export const Confirm = ({
                            isOpen = true,
                            icon = "",
                            cancelButtonText="Отмена",
                            confirmButtonText="OK",
                            onConfirm = ()=>{},
                            intent = Intent.PRIMARY,
                            children = (<p>Точно?</p>),
                        }) => {

    const [open, setOpen] = useState(isOpen)

    return(<Alert
        isOpen={open}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        icon={icon}
        canEscapeKeyCancel={true}
        canOutsideClickCancel={true}
        intent={intent}

        onCancel={(it)=>{
            setOpen(false);
        }}

        onConfirm={(it)=>{
            onConfirm(it);
            setOpen(false);
        }}
    >
        { children }
    </Alert>
    )
}


export const Error = ({children = (<p>Что-то пошло не так</p>)}) => {
    return(<Confirm
                            icon={"error"}
                                cancelButtonText={false}
                                intent={Intent.DANGER}
        >
            {children}
        </Confirm>
    )
}

export const ErrorNotAuth = ({children = (<>
    <p><b>Вы не авторизованы</b></p>
    <AnchorButton
        minimal={true}
        icon={"log-in"}
        href="/login"
    >Авторизация</AnchorButton>
</>)}) => {
    return(<Confirm
            icon={"user"}
            cancelButtonText={false}
            intent={Intent.DANGER}
        >
            {children}
        </Confirm>
    )
}
