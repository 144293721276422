import "@blueprintjs/table/lib/css/table.css";

import "./style.css";

import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";

import {
    AnchorButton,
    Button, Card, ControlGroup, FormGroup,
    HTMLTable,
    InputGroup,
    Intent, NonIdealState, Spinner,
    Tag
} from "@blueprintjs/core";

import DateInput, {formatDateLocal, parseDate} from "../../core/DateInput";

import SortButtons from "../Accs/SortButtons";
import Keywords from "./Keywords";
import Reports from "./Reports";

let aborterFetchPps // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!

const fetchPps = (setter, period, stateSetter)=> {
    if(!(period && (period.from || period.to))) return;

    stateSetter({type: "wait", description: "Подготавливается запрос"}) // setter( [] );

    if(aborterFetchPps){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        aborterFetchPps.abort() // отменить выполняющийся запрос
    }
    aborterFetchPps = new AbortController(); // на каждый запрос нужен свой абортарий


    const urlpars = [];
    if(period && period.from && period.from != "NaN"){
        try {
            urlpars.push("from="+period.from.getTime())
        }catch (e) {
            console.log(e)
        }
    }
    if(period && period.to && period.to != "NaN"){
        try{
            urlpars.push("to="+period.to.getTime())
        }catch (e) {
            console.log(e)
        }
    }

    window.fetch(
        "/paymentProducts?"+urlpars.join("&")
        ,{
            signal: aborterFetchPps.signal // привязка запроса к абортарию
        })
        .then(it => {
            stateSetter({type: "wait", description: "Передаётся перечень номенклатур"})
            return it.json()
        })
        .then( it => {
            stateSetter({type: "wait", description: "Подготавливается перечень номенклатур"})
            setter(
                it.map((t)=>{
                    t.category = t.category||{};
                    t.keywords = t.keywords||[];
                    return t;
                })
            );
            aborterFetchPps = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
            stateSetter({type: "ok"})
        })
        .catch( err => {
            stateSetter({type: "error", description: err})
            console.log(err);
            aborterFetchPps = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
        })
}

export default function Grid({
                       curUserInfo={state: false},
                       setPath=(path)=>{},
                       path = ""
                   }) {


    let [state, setState] = useState({type: ""});

    let [allPps, setAllPps] = useState([]);
    let [pps, setPps] = useState([]);

    let [keywordss, setKeywordss] = useState([]);

    let [selectedCategory, setSelectedCategory] = useState("");

    let [search, setSearch] = useState("");
    let [orderBy, setOrderBy] = useState({k:"datetime", o:-1});

    let [curPeriod, setCurPeriod] = useState({});

    useEffect(() => {
        const curday   =  new Date()
        const fromday =  new Date(curday.getFullYear(), curday.getMonth(), 1)
        const today = curday
        today.setDate(today.getDate()+1)
        setCurPeriod({from: fromday, to: today})
    }, []);


    useEffect(() => {
        if(curUserInfo.sub && curPeriod) {

            fetchPps((pps)=>{
                setAllPps(pps);
            }, curPeriod, setState);
        }
    }, [curPeriod, curUserInfo]);

    useEffect(() => {
        if(allPps) {
            filter();

            {
                const ks = allPps.flatMap((w) => (w.keywords||[]).map(k=>k.code)).filter(k=>((k||"").trim().length))
                setKeywordss(ks.filter((k, i) => (i == ks.indexOf(k))));
            }
        }
    }, [allPps]);

    useEffect(() => {
        filter()
    }, [search]);

    useEffect(() => {
        setPps(
            JSON.parse(JSON.stringify( sorting(pps) ))
        )
    }, [orderBy]);

    const filter = function(){ // Поиск
        const filterePps = allPps.filter(it => {
                if(search==="") return 1;
                const text = (it.id
                    + " " + (it.product.code || "")+ " " + (it.product.title || "")
                    + " " + it.description
                    + " " + (it.keywords||[]).map( it => it.code + " " + it.title).join(" ")
                    + " " + (it.category.code || "") + " " + (it.category.title || "")
                ).trim().toUpperCase();
                if(text.length>0){
                    return search
                        .split(/;\s*/)
                        .filter(it => (it.trim().length))
                        .filter((s)=>( text.indexOf(s.trim().toUpperCase())+1 ) )
                        .length;
                }else{
                    return 0;
                }
            }).filter( it => {
                return (!selectedCategory || ((it.category||{}).code === selectedCategory))
            })
        ;
        setPps( sorting(filterePps) );
    }

    const sorting = function (items){
        return items.sort( (a, b)=> {

            const sortPath = (orderBy.k || "").split(".")

            let o_a = a
            let o_b = b

            for( let index=0; sortPath.length > index; index++ ){
                o_a = o_a[sortPath[index]]
                o_b = o_b[sortPath[index]]
            }

            o_a = o_a||""
            o_b = o_b||""

            if ( o_a > o_b ) {
                return orderBy.o
            } else {
                return -1*(orderBy.o)
            }
        })
    }


    return (<>
        <ControlGroup
            fill={true}
            className={"upline"}
        >

            <FormGroup
                label="Период"
                inline={true}
                className={"period-selector"}
            >
                <DateInput
                    value={curPeriod.from}
                    onChange={(str, dat)=>{
                        setCurPeriod( Object.assign({}, curPeriod, {from: dat} ) )
                    }}
                />
                <DateInput
                    value={curPeriod.to}
                    onChange={(str, dat)=>{
                        setCurPeriod( Object.assign({}, curPeriod, {to: dat} ) )
                    }}
                />
            </FormGroup>

            <InputGroup
                id="inpSearch"
                fill={false}
                type="search"
                placeholder="Поиск…"
                value={search}
                onChange={(e)=>{
                    setSearch(e.target.value)
                }}
            />
        </ControlGroup>

        <div className={"main-content"}>

            <Reports data={pps} />

            <Card
                compact={true}
                className={"pps-list"}
            >
                {
                    (state.type == "wait"
                            ? (<NonIdealState
                                icon={<Spinner/>}
                                title={ state.title || "Обождите, процесс в процессе"}
                                description={state.description || "Видимо очень много данных"}
                            />)
                            : (
                                state.type == "error"
                                    ? (<NonIdealState
                                        icon={"error"}
                                        title={ state.title || "Ошибочка"}
                                        description={state.description || "Что-то пошло не так"}
                                    />)
                                    : (
                                        pps.length == 0
                                            ? (<NonIdealState
                                                icon={"search-template"}
                                                title={ state.title || "Ни чего не найдено"}
                                                description={state.description || "Продуктов удовлетворяющих заданным критериям не обнаружено"}
                                            />)
                                            : (
                                                <HTMLTable
                                                    bordered={true}
                                                    striped={true}
                                                    interactive={true}
                                                >
                                                    <thead>
                                                    <tr className="Pps">
                                                        <th className="pps-datetime">
                                                            дата-время
                                                            <SortButtons
                                                                code={"payment.datetime"}
                                                                current={orderBy} chOrderBy={setOrderBy}
                                                            />
                                                        </th>
                                                        <th className="pps-agent">
                                                            Кто-где
                                                            <SortButtons
                                                                code={"payment.agent.code"}
                                                                current={orderBy} chOrderBy={setOrderBy}
                                                            />
                                                        </th>
                                                        <th className="pps-productCode">
                                                            Что
                                                            <SortButtons
                                                                code={"product.code"}
                                                                current={orderBy} chOrderBy={setOrderBy}
                                                            />
                                                        </th>
                                                        <th className="pps-sum">
                                                            Сумма
                                                            ≈ {Math.floor(pps.reduce((prev, cur) => {
                                                                    prev += (cur.sum || 0);
                                                                    return prev
                                                              }, 0))}

                                                            <SortButtons
                                                                code={"sum"}
                                                                current={orderBy} chOrderBy={setOrderBy}
                                                            />
                                                        </th>
                                                        <th className="pps-qty">
                                                            Кол-во
                                                            <SortButtons
                                                                code={"qty"}
                                                                current={orderBy} chOrderBy={setOrderBy}
                                                            />
                                                        </th>
                                                        <th className="pps-price">
                                                            Цена
                                                            <SortButtons
                                                                code={"price"}
                                                                current={orderBy} chOrderBy={setOrderBy}
                                                            />
                                                        </th>
                                                        <th className="pps-category">
                                                            Категория
                                                            <SortButtons
                                                                code={"category.code"}
                                                                current={orderBy} chOrderBy={setOrderBy}
                                                            />
                                                        </th>
                                                    </tr>
                                                    </thead>

                                                    <tbody>
                                                    {
                                                        pps.map((t) => (
                                                            <tr>
                                                                <td className="pps-datetime">
                                                                    { formatDateLocal(t.payment.datetime, true) }
                                                                </td>
                                                                <td className="pps-agent">
                                                                    {t.payment.agent.code}
                                                                </td>
                                                                <td className="pps-productCode">
                                                                    <span>{t.product.code}</span>
                                                                    {t.product.code == t.product.title ? <></> :
                                                                        <b className={"pps-productTitle"}>{t.product.title}</b>}
                                                                    {
                                                                        (t.keywords && t.keywords.length)
                                                                            ?
                                                                            <p className={"pps-keywords"}>{t.keywords.map(k => (
                                                                                <Tag
                                                                                    minimal={true}
                                                                                    className={
                                                                                        (search || "")
                                                                                            .split(";")
                                                                                            .filter(it =>
                                                                                                (it && it.trim().toLowerCase() == k.code.trim().toLowerCase())
                                                                                            )
                                                                                            .length > 0
                                                                                            ? "active" : ""
                                                                                    }
                                                                                >{k.code}</Tag>
                                                                            ))}</p>
                                                                            : <></>
                                                                    }
                                                                </td>
                                                                <td
                                                                    className="pps-sum"
                                                                >{t.sum}</td>
                                                                <td
                                                                    className="pps-qty"
                                                                >{t.qty}</td>
                                                                <td
                                                                    className="pps-price"
                                                                >{t.price}</td>
                                                                <td
                                                                    className="pps-category"
                                                                >{t.category.code}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th className="pps-datetime">

                                                            </th>
                                                            <th className="pps-agent">

                                                            </th>
                                                            <th className="pps-productCode">
                                                                <span>ИТОГО</span>
                                                            </th>
                                                            <th className="pps-sum">
                                                                ≈ {Math.floor(pps.reduce((prev, cur) => {
                                                                prev += (cur.sum || 0);
                                                                return prev
                                                            }, 0))}
                                                            </th>
                                                            <th className="pps-qty">

                                                            </th>
                                                            <th className="pps-price">

                                                            </th>
                                                            <th className="pps-category">

                                                            </th>
                                                        </tr>
                                                    </tfoot>
                                                </HTMLTable>
                                            )
                                    )
                            )
                    )
                }
            </Card>

            <Keywords keywords={keywordss} search={search} chSearch={setSearch}/>

        </div>

    </>);
}


