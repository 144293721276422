import React, {useEffect, useState} from "react";
import {AnchorButton, Button} from "@blueprintjs/core";

function LinkMan({
                       setPath=(path)=>{},
                       href="",
                       children
}) {
    return(<Button
                onClick={()=>{
                    if(href.slice(0,1) == "#"){
                        const trgt = document.getElementById( href.slice(1) );
                        if(trgt) trgt.scrollIntoView({behavior: "smooth"})
                        else window.location.href = href;
                    }else {
                        setPath("/mans/" + href)
                    }
                }}
              >{children}</Button>
    )
}

export default LinkMan;
