import "./style.css"

import {
    AnchorButton,
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    InputGroup,
    Switch, TagInput, TextArea
} from "@blueprintjs/core";


import React, {useEffect, useId, useRef, useState} from "react";

import DateInput, {formatDate} from "../../../core/DateInput";

export default function Editor ({
                     payment = {}, // as TemplateBaseInfo
                     onSave = (accData)=>{},
                     onClose = ()=>{},
                  }) {

    let [paymentData, setPaymentData] = useState(payment)
    function setPayment(newState={}){
        for(const k in newState){
            paymentData[k] = newState[k];
        }
        setPaymentData(Object.assign({}, paymentData))
    }

    let [isOpen, setIsOpen] = useState(true)

    /*
    const [keywords, setKeywords] = useState(payment.keywords||[])
    useEffect(() => {
        setPayment({keywords: keywords})
    }, [keywords]);
    */

    const id = useId()

    const diaRef = useRef(null)

    const handleClose = ()=>{
        onClose && onClose()
        setIsOpen(false)
        try {
            diaRef && diaRef.current && diaRef.current.remove()
        }catch (_) {
            // ну не получилось и не получилось
        }
    }

    return(<Dialog
            title={ paymentData.id ? "Редактирование рассчёта «" + paymentData.id + "»" : "Добавление нового рассчёта"}
            style={{width: "clamp(30%, 456px, 90%)"}}
            className="dialog-style"
            isOpen={isOpen}
            onClose={handleClose}
            canEscapeKeyClose={true}
            canOutsideClickClose={false}
            enforceFocus={true}
            shouldReturnFocusOnClose={true}
            containerRef={diaRef}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    // const formData = new FormData(e.target);
                    // formData.set("keywords", paymentData.keywords.join("; "));
                    // onSave(formData);

                    if(paymentData.datetime && paymentData.datetime.indexOf("T")<0) paymentData.datetime=paymentData.datetime+"T00:00:00"
                    if(paymentData.agent && paymentData.agent.code) paymentData.agentCode = paymentData.agent.code

                    onSave(paymentData)
                    handleClose();
                }}
            >

                <DialogBody>

                    <FormGroup
                        label="Дата-время"
                        labelFor={"datetime-" + id}
                        className="w⅓"
                    >
                        <DateInput
                            value={paymentData.datetime || (new Date()).toISOString() }
                            withTime={true}
                            onChange={(str, dat) => {
                                setPayment({datetime: str})
                            }}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Где-Кто"
                        labelFor={"agent-" + id}
                        className="w⅔"
                    >
                        <InputGroup id={"agent-" + id} type="text" name="agent" value={paymentData.agent.code}
                                    onChange={(e) => {
                                        setPayment({agent: {code: e.target.value}})
                                    }}
                                    required/>
                    </FormGroup>

                    <FormGroup
                        label="Описание"
                        labelFor={"description-" + id}
                        className="w1"
                    >
                        <TextArea id={"description-" + id} name="description" fill={true}
                                  onChange={(e) => {
                                      setPayment({description: e.target.value})
                                  }}
                                  autoResize={true}>{paymentData.description}</TextArea>
                    </FormGroup>

                </DialogBody>

                <DialogFooter actions={<>
                    <Button type="reset" text="Отмена" onClick={handleClose}/>
                    <Button type="submit" text="Сохранить" intent="primary"/>
                </>}
                />
            </form>
        </Dialog>
    )
}


