
import { MenuItem } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import {useEffect, useState} from "react";

let aborterFetchItems // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!
const fetchItems = (setter)=> {
    if(aborterFetchItems){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        aborterFetchItems.abort() // отменить выполняющийся запрос
    }
    aborterFetchItems = new AbortController(); // на каждый запрос нужен свой абортарий

    window.fetch(
        "/products"
        ,{
            signal: aborterFetchItems.signal // привязка запроса к абортарию
        })
        .then(it => it.json() )
        .then(it => it.sort((a,b)=>( a.code > b.code )) )
        .then( it => {
            setter(it);
            aborterFetchItems = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
        })
        .catch( err => {
            console.log(err);
            aborterFetchItems = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
        })
}


export default function ProductSuggestionator({
    currentItem = {},
    onChange = (itm)=>{}
                                        }){
    const [items, setItems] = useState([])
    const [selectedItem, setSelectedItem] = useState(currentItem)

    useEffect(() => {
        setSelectedItem(items.filter((it)=>( itemsIsEqual(it, currentItem) ))[0])
    }, [currentItem, items]);
    useEffect(() => {
        fetchItems(setItems)
    }, []);

    useEffect(() => {
        console.log("useEffect selectedItem: ", selectedItem)
        if(selectedItem && selectedItem.code) onChange(selectedItem)
    }, [selectedItem]);

    const itemsIsEqual = (a, b) => ( a && b && ((a.code || "").toLowerCase() === (b.code||"").toLowerCase()))

    return (
                <Suggest
                    items={items}
                    createNewItemFromQuery={ (query)=>({code: query, title: query}) }
                    createNewItemRenderer={(query, active, handleClick) => (
                                                <MenuItem
                                                    icon="add"
                                                    text={`Добавить "${query}"`}
                                                    roleStructure="listoption"
                                                    active={active}
                                                    onClick={handleClick}
                                                    shouldDismissPopover={false}
                                                />
                                          )}
                    inputValueRenderer={(itm) => (itm.code)}
                    itemPredicate={ (query, item, _index, exactMatch) => {
                        const normalizedTitle = (item.title||"").toLowerCase();
                        const normalizedQuery = query.toLowerCase();
                        if (exactMatch) {
                            return normalizedTitle === normalizedQuery;
                        }
                        else {
                            return `${item.code}. ${normalizedTitle}`.indexOf(normalizedQuery) >= 0;
                        }
                    } }
                    itemRenderer={ (item, props) => (
                            <MenuItem
                                text={item.code}
                                selected={ selectedItem && ( item.code == selectedItem.code ) }
                                active={ selectedItem && ( item.code == selectedItem.code ) }
                                disabled={props.modifiers.disabled}
                                onClick={props.handleClick}
                                roleStructure="listoption"
                            />
                        )
                    }
                    noResults={<MenuItem disabled={true} text="Ни чего не найдено" roleStructure="listoption" />}

                    selectedItem={selectedItem}
                    onItemSelect={ (item, e) => {
                        console.log("onItemSelect: ", item)
                        setSelectedItem(item)
                    } }

                    itemsEqual={itemsIsEqual}
                    scrollToActiveItem={true}
                    closeOnSelect={true}
                    openOnKeyDown={false}
                    resetOnQuery={true}
                    createNewItemPosition={"last"}
                    inputProps = {{placeholder: "Поиск…", value: currentItem.code}}
                    popoverProps = {{matchTargetWidth: true}}
                    />
    )
}
