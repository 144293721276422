import {Button, Card, Dialog, DialogBody, DialogFooter} from "@blueprintjs/core";

import React, {useEffect, useRef, useState} from "react";

export default function Reports({
                      data=[]
}) {
    const [isOpen, setIsOpen] = useState(false)
    const [diaTitle, setDiaTitle] = useState("")
    const [rptData, setRptData] = useState(<></>)
    const diaRef = useRef(null)

    function doRpt(code, xml, title){

        const fd = new FormData();
        fd.set("tcode", code)
        fd.set("ff", "html")
        fd.set("data", xml)

        fetch(((window["config"] && window["config"]["SGR_URL"]) || "//sgr.geniyz.site")+"/render", {method: "POST", body: fd})
            .then( it => it.text())
            .then( it => {
                setDiaTitle(title)
                setRptData(<div dangerouslySetInnerHTML={{__html: it}} />)
                setIsOpen(true)
            })
    }

    function grafByCategories(data = []) {
        const _body = document.createElement("body");
        _body.innerHTML = '<data><s name="report"></s></data>';
        const sheet = _body.querySelector("[name='report']");

        const _commands = document.createElement("_commands");
        const colGenerate = document.createElement("colGenerate");
        colGenerate.setAttribute("as", "col");
        colGenerate.setAttribute("to", "100");
        _commands.appendChild(colGenerate);
        sheet.appendChild(_commands);

        console.log(data);

        let totalSum = 0;
        const cats = data.reduce((prev, cur)=>{
            const catCode = (cur.category ? cur.category.code : "") || "без категории";
            prev[catCode] = (prev[catCode]||0) -0 + (cur.sum||0);

            totalSum += (cur.sum||0);

            console.log(  "    "+catCode+" += "+(cur.sum||0));

            return prev;
        }, {});
        Object.keys(cats).forEach( k => {
            const sum = cats[k];
            const perc = Math.floor(sum*100/totalSum);

            const line = document.createElement("line");
            const line_title = document.createElement("line_title");
            const ln = document.createElement("ln");
            const nn = document.createElement("ln");

            line_title.textContent = k;

            ln.textContent = Math.round(sum);
            ln.setAttribute("col", "col.1");
            ln.setAttribute("colspan", perc);
            ln.setAttribute("background", "blue");
            ln.setAttribute("color", "white");

            nn.setAttribute("col", "col."+(perc+1));
            nn.setAttribute("colspan", 100-perc);
            nn.setAttribute("background", "white");
            nn.setAttribute("color", "blue");
            nn.textContent = perc+"%";

            line.appendChild(line_title);
            line.appendChild(ln)
            line.appendChild(nn)

            sheet.appendChild(line);
        });

        doRpt("bux_grid", _body.innerHTML, "Отчёт по категориям")
    }

    const handleClose = ()=>{
        setIsOpen(false)
    }

    return (<>
            <Card
                className={"reports-list"}
                compact={true}
            >
                <Button onClick={()=>{
                    grafByCategories(data, setRptData)
                }} >График по категориям</Button>
            </Card>
            <Dialog
                title={diaTitle}
                style={{width: "clamp(500px, 100%, 1024px)"}}
                isOpen={isOpen}
                onClose={handleClose}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                enforceFocus={true}
                shouldReturnFocusOnClose={true}
                containerRef={diaRef}
            >
                <DialogBody>{rptData}</DialogBody>
                <DialogFooter actions={<Button type="reset" text="Закрыть" onClick={handleClose} />} />
            </Dialog>
        </>
    );
}
