import "./style.css"

import "@blueprintjs/table/lib/css/table.css";

import {Button} from "@blueprintjs/core";

import React from "react";

function SortButtons({
                         code = "",
                         current={},
                         chOrderBy=(orderBy)=>{},
                   }) {

  return (<p className={"resort current"+(current.k||"")+" ord-"+(code||"")}>
          <Button
              onClick={() => {
                  chOrderBy({k: code, o: -1})
              }}
              icon={"sort-desc"}
              minimal={true}
              active={(current.k == code && current.o == -1)}
          />
          <Button
              onClick={() => {
                  chOrderBy({k: code, o: 1})
              }}
              icon={"sort-asc"}
              minimal={true}
              active={(current.k == code && current.o == 1)}
          />
      </p>)
}

export default SortButtons;
