import "@blueprintjs/table/lib/css/table.css";

import "./style.css";

import {
    Button, Card, ControlGroup, FormGroup,
    HTMLTable,
    InputGroup,
    Intent, NonIdealState, Spinner,
    Tag
} from "@blueprintjs/core";

import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";

// import Storages from "./Storages";
import {getOrCreate} from "../../../core/fncs";
import {Confirm, Error, ErrorNotAuth} from "../../../core/Confirm";
import Editor from "./Editor";
import Categories from "./Categories";
import Keywords from "./Keywords";
import SortButtons from "../SortButtons";

const emptyPP = {payment: {}, product: {}, category:{}, keywords:[]}

let aborterFetchPps // = new AbortController() // на каждый запрос нужен свой абортарий! Один на все случаи делать нельзя!

const fetchPps = (setter, aid, stateSetter)=> {
    if(!(aid)) return;

    stateSetter({type: "wait", description: "Подготавливается запрос"}) // setter( [] );

    if(aborterFetchPps){ // наличие абортария - по совместительству признак того, что какой-то запрос в процессе исполнения
        // если какой-то запрос в процессе выполнения:
        aborterFetchPps.abort() // отменить выполняющийся запрос
    }
    aborterFetchPps = new AbortController(); // на каждый запрос нужен свой абортарий

    window.fetch(
        "/paymentProducts?pid="+aid
        ,{
            signal: aborterFetchPps.signal // привязка запроса к абортарию
        })
        .then(it => {
            stateSetter({type: "wait", description: "Передаётся перечень номенклатур"})
            return it.json()
        })
        .then( it => {
            stateSetter({type: "wait", description: "Подготавливается перечень номенклатур"})
            setter(
                it.map((t)=>{
                    t.category = t.category||{};
                    t.keywords = t.keywords||[];
                    return t;
                })
            );
            aborterFetchPps = null; // уничтожение абортария - запрос завершён (успешно), он уже не нужен
            stateSetter({type: "ok"})
        })
        .catch( err => {
            stateSetter({type: "error", description: err})
            console.log(err);
            aborterFetchPps = null; // уничтожение абортария - запрос завершён (с ошибкой), он уже не нужен
        })
}

export default function PayProducts({
                                        accId = "",
                       curUserInfo={state: false},
                       setPath=(path)=>{},
                       path = ""
                   }) {

    let [state, setState] = useState({type: ""});

    let [editorFor, setEditorFor] = useState("");

    let [allPps, setAllPps] = useState([]);
    let [pps, setPps] = useState([]);

    let [categories, setCategories] = useState([]);
    let [keywordss, setKeywordss] = useState([]);

    let [selectedCategory, setSelectedCategory] = useState("");

    let [search, setSearch] = useState("");
    let [orderBy, setOrderBy] = useState({k:"code", o:1});

    useEffect(() => {
        if(accId) {

            if("" != selectedCategory)  setSelectedCategory("")
            if("" != editorFor)         setEditorFor("")

            fetchPps((pps)=>{
                setAllPps(pps);
                // setCurAcc({});
            }, accId, setState);
        }
    }, [accId]);

    useEffect(() => {
        if(allPps) {
            filter();
            // setCurTmpl({});

            {
                // const ks = allPps.map((w) => (w.category)).filter(k=>((k||"").trim().length))
                const ks = allPps.map((w) => (w.category.code)).filter(k=>((k||"").trim().length))
                setCategories( ks.filter((k, i) => (i == ks.indexOf(k))) );
            }

            {
                const ks = allPps.flatMap((w) => (w.keywords||[]).map(k=>k.code)).filter(k=>((k||"").trim().length))
                setKeywordss(ks.filter((k, i) => (i == ks.indexOf(k))));
            }
        }
    }, [allPps]);

    useEffect(() => {
        filter()
    }, [selectedCategory, search]);

    useEffect(() => {
        setPps(
            JSON.parse(JSON.stringify( sorting(pps) ))
        )
    }, [orderBy]);

    useEffect(()=>{
        if( editorFor && allPps && allPps.length && curUserInfo && curUserInfo.state )
            showFormForEditPP(allPps.filter(it=> (editorFor == it.id))[0])
    }, [editorFor, allPps, curUserInfo])

    const deletePp = function (acc, setter) {
        if( curUserInfo && curUserInfo.state ) {
            if (curUserInfo && curUserInfo.sub) {

                ReactDOM.createRoot(getOrCreate()).render((<Confirm
                    isOpen={true}
                    cancelButtonText="Отмена"
                    confirmButtonText="Удалить"
                    icon="trash"
                    intent={Intent.DANGER}

                    onConfirm={() => {
                        fetch("/payments", {method: "POST", body: JSON.stringify({type: "paymentDelete", data: acc})})
                            .then(it => {
                                return it.json()
                            })
                            .then(it => {
                                if (it.id === acc.id) {
                                    setter(pps.filter(it => {
                                        return it.id !== acc.id
                                    }))
                                    // setCurTmpl({});
                                } else {
                                    // window.alert(it.code+": "+it.title+"\r\n"+it.description)
                                    ReactDOM.createRoot(getOrCreate())
                                        .render((<Error><b>{it.code}</b><p>{it.title}</p>
                                            <code>{it.description}</code></Error>))
                                }
                            });
                    }}
                >
                    <p>
                        Удалить рассчёт «{acc.id}»?
                    </p>
                </Confirm>))

            } else {
                ReactDOM.createRoot(getOrCreate()).render((<ErrorNotAuth/>))
            }
        }
    }

    const savePp = function (reqData, callback) {
        if( curUserInfo && curUserInfo.sub ) {
            fetch("/paymentProducts", {method: "POST", body: JSON.stringify(reqData)})
                .then( it => { return it.json() })
                .then( it => {
                    if( it.type === "err" ){
                        // alert( it.code +": "+ it.title + "\r\n\r\n" + it.description)
                        ReactDOM.createRoot( getOrCreate() )
                            .render((<Error><b>{it.code}</b><p>{it.title}</p><code>{it.description}</code></Error>))

                    }else {
                        fetchPps( (arr)=>{
                            setAllPps(arr)
                            // setCurAccId(it.id);
                            callback && callback(it);
                        }, accId, setState);
                        setEditorFor("");
                    }
                });
        }else{
            ReactDOM.createRoot( getOrCreate() ).render((<ErrorNotAuth/>))
        }
    }

    const showFormForEditPP = function (pp) {
        if(curUserInfo && curUserInfo.state) {
            if (curUserInfo && curUserInfo.sub) {
                ReactDOM.createRoot(getOrCreate()).render(
                    (<Editor
                        paymentProduct={pp}
                        onSave={(ppData)=>{
                            ppData.payment.id = accId
                            savePp( {
                                type: (pp==emptyPP ? "payProductAdd" : "payProductUpdate"),
                                asDef: (ppData.asDef||false),
                                data: ppData
                            }, (p)=>{
                                // setCurPpId(p.id)
                            } )
                        }}
                        onClose={() => {
                            setEditorFor("")
                        }}
                        setPath={setPath}
                        path={path}
                    />))
            } else {
                ReactDOM.createRoot(getOrCreate()).render((<ErrorNotAuth/>))
            }
        }
    }

    const filter = function(){ // Поиск
        const filterePps = allPps.filter(it => {
                if(search==="") return 1;
                const text = (it.id
                    + " " + (it.product.code || "")+ " " + (it.product.title || "")
                    + " " + it.description
                    + " " + (it.keywords||[]).map( it => it.code + " " + it.title).join(" ")
                    + " " + (it.category.code || "") + " " + (it.category.title || "")
                ).trim().toUpperCase();
                if(text.length>0){
                    return search
                        .split(/;\s*/)
                        .filter(it => (it.trim().length))
                        .filter((s)=>( text.indexOf(s.trim().toUpperCase())+1 ) )
                        .length;
                }else{
                    return 0;
                }
            }).filter( it => {
                return (!selectedCategory || ((it.category||{}).code === selectedCategory))
            })
        ;
        setPps( sorting(filterePps) );
        setEditorFor("");
    }

    const sorting = function (items){
        return items.sort( (a, b)=> {

            const sortPath = (orderBy.k || "").split(".")

            let o_a = a
            let o_b = b

            for( let index=0; sortPath.length > index; index++ ){
                o_a = o_a[sortPath[index]]
                o_b = o_b[sortPath[index]]
            }

            o_a = o_a||""
            o_b = o_b||""

            if ( o_a > o_b ) {
                return orderBy.o
            } else {
                return -1*(orderBy.o)
            }
        })
    }

  return (<>
      <ControlGroup
          fill={true}
          className={"upline"}
      >
          <InputGroup
              id="inpSearch"
              fill={false}
              type="search"
              placeholder="Поиск…"
              value={search}
              onChange={(e)=>{
                  setSearch(e.target.value)
              }}
          />
      </ControlGroup>

      <div className={"main-content"}>

          <Categories
              categories={categories}
              selectedCategory={selectedCategory}
              chCaterory={setSelectedCategory}
          />

          <Card
              compact={true}
              className={"pps-list"}
          >
              {
                  (state.type == "wait"
                          ? (<NonIdealState
                                icon={<Spinner/>}
                                title={ state.title || "Обождите, процесс в процессе"}
                                description={state.description || "Видимо очень много данных"}
                          />)
                      : (
                              state.type == "error"
                                  ? (<NonIdealState
                                      icon={"error"}
                                      title={ state.title || "Ошибочка"}
                                      description={state.description || "Что-то пошло не так"}
                                  />)
                                  : (
                                      pps.length == 0
                                          ? (<NonIdealState
                                              icon={"search-template"}
                                              title={ state.title || "Ни чего не найдено"}
                                              description={state.description || "Продуктов удовлетворяющих заданным критериям не обнаружено"}
                                              action={<>
                                                  <Button id={"btnToPayProductAdd"}
                                                          icon="plus"
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    e.preventDefault()
                                                                    showFormForEditPP(emptyPP)
                                                                }}
                                                  >Добавить</Button>
                                              </>}
                                          />)
                                          : (
                                              <HTMLTable
                                                  bordered={true}
                                                  striped={true}
                                                  interactive={true}
                                              >
                                                  <thead>
                                                  <tr className="Pps">
                                                      <th className="pps-productCode">
                                                          Что
                                                          <SortButtons
                                                              code={"product.code"}
                                                              current={orderBy} chOrderBy={setOrderBy}
                                                          />
                                                      </th>
                                                      <th className="pps-sum">
                                                          Сумма
                                                          ≈ {Math.floor(pps.reduce((prev, cur) => {
                                                                prev += (cur.sum || 0);
                                                                return prev
                                                            }, 0))}
                                                          <SortButtons
                                                              code={"sum"}
                                                              current={orderBy} chOrderBy={setOrderBy}
                                                          />
                                                      </th>
                                                      <th className="pps-qty">
                                                          Кол-во
                                                          <SortButtons
                                                              code={"qty"}
                                                              current={orderBy} chOrderBy={setOrderBy}
                                                          />
                                                      </th>
                                                      <th className="pps-price">
                                                          Цена
                                                          <SortButtons
                                                              code={"price"}
                                                              current={orderBy} chOrderBy={setOrderBy}
                                                          />
                                                      </th>
                                                      <th className="pps-category">
                                                          Категория
                                                          <SortButtons
                                                              code={"category.code"}
                                                              current={orderBy} chOrderBy={setOrderBy}
                                                          />
                                                      </th>
                                                      {
                                                          curUserInfo.sub
                                                              ? <th className="pps-actions">
                                                                      <Button icon="plus"
                                                                              onClick={(e) => {
                                                                                  e.stopPropagation()
                                                                                  e.preventDefault()
                                                                                  showFormForEditPP(emptyPP)
                                                                              }}
                                                                      >Добавить</Button>
                                                              </th>
                                                              : <></>
                                                      }
                                                  </tr>
                                                  </thead>

                                                  <tbody>
                                                  {
                                                      pps.map((t) => (
                                                          <tr
                                                          >
                                                              <td
                                                                  className="pps-productCode"
                                                              >
                                                                  <span>{t.product.code}</span>
                                                                  { t.product.code == t.product.title ? <></> : <b className={"pps-productTitle"}>{t.product.title}</b> }
                                                                  {
                                                                      (t.keywords && t.keywords.length)
                                                                          ? <p className={"pps-keywords"}>{t.keywords.map(k => (
                                                                              <Tag
                                                                                  minimal={true}
                                                                                  className={
                                                                                      (search||"")
                                                                                              .split(";")
                                                                                              .filter( it =>
                                                                                                  (it && it.trim().toLowerCase() == k.code.trim().toLowerCase())
                                                                                              )
                                                                                              .length > 0
                                                                                      ? "active" : ""
                                                                                  }
                                                                              >{k.code}</Tag>
                                                                          ))}</p>
                                                                          : <></>
                                                                  }
                                                              </td>
                                                              <td
                                                                  className="pps-sum"
                                                              >{t.sum}</td>
                                                              <td
                                                                  className="pps-qty"
                                                              >{t.qty}</td>
                                                              <td
                                                                  className="pps-price"
                                                              >{t.price}</td>
                                                              <td
                                                                  className="pps-category"
                                                              >{t.category.code}</td>

                                                              {curUserInfo.sub
                                                                  ? <td
                                                                      className="pps-actions"
                                                                  >
                                                                      <Button minimal={true} text="Удалить"
                                                                              icon="delete"
                                                                              onClick={(e) => {
                                                                                  e.stopPropagation()
                                                                                  e.preventDefault()
                                                                                  deletePp(t, setPps)
                                                                              }}
                                                                      />
                                                                      <Button minimal={true} text="Изменить" icon="edit"
                                                                              onClick={(e) => {
                                                                                  e.stopPropagation()
                                                                                  e.preventDefault()
                                                                                  setEditorFor(t.id)
                                                                              }}
                                                                      />
                                                                  </td>
                                                                  : <></>
                                                              }
                                                          </tr>
                                                      ))
                                                  }
                                                  </tbody>
                                              </HTMLTable>
                                          )
                                  )
                          )
                  )
              }
          </Card>

          <Keywords keywords={keywordss} search={search} chSearch={setSearch}/>

      </div>

  </>);
}
