import "@blueprintjs/table/lib/css/table.css";

import {
    Button, Dialog, DialogBody, DialogFooter,
    TextArea
} from "@blueprintjs/core";



import React, {useCallback, useEffect, useState} from "react";

export default function AccLoader({
                     afterLoad = (rez=[])=>{}
                   }) {

    let [loaderOpen, setLoaderOpen] = useState(true);
    let [data, setData] = useState("");

    const loadPays = function () {
                                        const fd = new FormData()
                                        fd.append("data", data)
                                        fetch("/payload", {method: "POST", body: fd})
                                            .then( it => { return it.json() })
                                            .then( it => {
                                                if( it.type === "err" ){
                                                    alert( it.id +": "+ it.nomen + "\r\n\r\n" + it.description)
                                                }else {
                                                    afterLoad(it)
                                                }
                                            });
                                    setLoaderOpen(false)
    }

  return (<Dialog
      isOpen={loaderOpen}
      title="Загрузка чека"
      icon={"cloud-upload"}
      style={{
          width:  "clamp(27vw, 345px, 90%)",
          height: "clamp(53vh, 345px, 90%)",
         }}
      canOutsideClickClose={true}
      canEscapeKeyClose={true}
      onClose={()=>{
          setLoaderOpen(false)
      }}
  >
      <DialogBody>
          <TextArea
              style={{minHeight: "47vh"}}
              autoFocus={true}
              fill={true}
              value={data}
              autoResize={true}
              onChange={(e)=>{
                  setData(e.target.value)
              }}
          ></TextArea>
      </DialogBody>
      <DialogFooter
          actions={
              <Button
                  intent="primary"
                  text="Загрузить"
                  onClick={loadPays}
              />
          }/>
  </Dialog>);
}

