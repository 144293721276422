import './style.css';

import React, {useEffect, useState} from "react";
import Header from "./Header";
import Man from "./Man";
import Footer from "./Footer";
import Accs from "./Accs";
import Grid from "./Grid";

const fillConfig = function (  ) {
    fetch("/config")
        .then(it => {
            return it.json()
        })
        .then(it => {
            window["config"] = it
        })
}

const fetchSessData = function ( setter ) {
  fetch("/sess")
      .then(it => {
        return it.json()
      })
      .then(usr => {
          usr.state = true
        const lsKey =  "user-"+usr.sub
        if(usr.acc) window["acc"] = usr.acc;

        usr.displayName = [usr.family_name, usr.given_name, usr.middle_name].join(" ").trim();
        if(usr.displayName.length === 0) usr.displayName = usr.nickname;
        if( usr.picture && usr.picture.slice(0,4).toLowerCase() === "http" ){ //если url, то получаем по нему картинку
          const pic = usr.picture;
          const kache = sessionStorage.getItem(pic);
          if (kache) {
            usr.picture = kache;
            setter(usr);
          }else {
            fetch(pic, {method: "GET", headers: {"Authorization": "Bearer " + usr.acc}})
                .then(it => {
                  return it.blob()
                })
                .then((it) => {
                  const fr = new FileReader();
                  fr.readAsDataURL(it);
                  fr.onloadend = () => {
                    usr.picture = "";
                    if(fr.result.indexOf("data:application/json") < 0) { //если не ошибка
                      sessionStorage.setItem(pic, fr.result); //кешируем
                      usr.picture = fr.result; //сохраняем у пользователя вместо url саму картинку
                      sessionStorage.setItem(lsKey, JSON.stringify( usr )) // записать в кэш
                      setter(usr); // перерисовываем инфо о пользоваетеле
                    }else{
                      setter(usr); // если ошибка, то не кешируем
                    }
                  }
                })
                .catch(it => { // ну, не вышло, и не вышло (
                  console.log(it)
                  setter(usr);
                })
          }
        }else {
          sessionStorage.setItem(lsKey, JSON.stringify( usr )) // записать в кэш
          setter(usr)
        }
      })
}

function App() {

    let [sessData, setSessData] = useState({state: false});
    let [path, setP] = useState("/accs");

    function setPath(
        p
        , replace=false
    ){
        const ath = ( ( [ "/" ].indexOf(p)+1 ) ? "/accs" : p )

        setP(ath)

        const data = ath
        const url = (ath == "/accs") ? "/" : ath

        if(replace){
            window.history.replaceState(data, "", url)
        }else{
            window.history.pushState(data, "", url)
        }
    }

  useEffect(() => {
      fillConfig();

      window.addEventListener("popstate", (e)=>{
          setPath( document.location.pathname )
      })

      fetchSessData( setSessData );

      const sp = document.location.href.split("?p=")[1]
      if(sp) setPath( decodeURIComponent(sp), true )

  }, []);

  return (<>
          <Header curUserInfo={sessData} setPath={setPath} path={ path } />
          <div className={"main"}>
              { ( 0 != path.indexOf("/accs" ) ) ? <></> : <Accs curUserInfo={sessData} setPath={setPath} path={ path } /> }
              { ( 0 != path.indexOf("/mans/") ) ? <></> : <Man  curUserInfo={sessData} setPath={setPath} path={ path } /> }
              { ( 0 != path.indexOf("/grid/") ) ? <></> : <Grid curUserInfo={sessData} setPath={setPath} path={ path } /> }
          </div>
          <Footer curUserInfo={sessData} setPath={setPath} path={ path } />
      </>
  );
}

export default App;
