
import {Alignment, AnchorButton, Button, Navbar} from "@blueprintjs/core";
import React from "react";

function Header({
                    curUserInfo = {},
                    setPath=(path)=>{},
                    path="",
}) {

  return (<Navbar
      fixedToTop={true}
      style={{background: "linear-gradient(rgb(255, 255, 255) 0%, rgb(245, 245, 245) 100%)"}}
      >
      <Navbar.Group
      align={Alignment.LEFT}
                  onClick={()=>{ setPath("/") }}
              >
                  <img src={"/favicon.ico"}  alt={"×"} style={{maxHeight: "100%", position: "relative", left: "-1em"}} />
                  <Navbar.Heading
                      title="">
                      BUX
                  </Navbar.Heading>
                  <Navbar.Divider/>
              </Navbar.Group>

              <Navbar.Group align={Alignment.RIGHT}>
                  <Navbar.Heading>{
                      curUserInfo.sub ? (
                              <form id="logoff_form" action="/logoff" method="POST">
                                  <img alt="😎" src={curUserInfo.picture64 || curUserInfo.picture}/>
                                  <span>{curUserInfo.displayName || curUserInfo.sub}</span>
                                  <Button
                                      type={"submit"}
                                      rightIcon={"log-out"}
                                  >Выход</Button>
                              </form>
                          ) : (
                              <AnchorButton
                                  icon={"log-in"}
                                  href="/login"
                              >Авторизация</AnchorButton>
                          )
                  }</Navbar.Heading>
              </Navbar.Group>

              <Navbar.Group align={Alignment.RIGHT}>
                  {( 0 == path.indexOf("/accs"))
                      ? <></>
                      : <Button
                          onClick={()=>{setPath("/accs/")}}
                          target={"_blank"}
                          minimal={true}
                          icon={"bank-account"}
                      >Учёт</Button>
                  }
                  {( 0 == path.indexOf("/grid"))
                      ? <></>
                      : <Button
                          onClick={()=>{setPath("/grid/")}}
                          target={"_blank"}
                          minimal={true}
                          icon={"list-detail-view"}
                      >Таблица</Button>
                  }
                  {( 0 == path.indexOf("/rpts"))
                      ? <></>
                      : <Button
                          onClick={()=>{setPath("/rpts/")}}
                          target={"_blank"}
                          minimal={true}
                          icon={"projects"}
                      >Аналитика</Button>
                  }
                  {( 0 == path.indexOf("/mans"))
                      ? <></>
                      : <Button
                          onClick={()=>{setPath("/mans/")}}
                          target={"_blank"}
                          minimal={true}
                          icon={"manual"}
                      >Документация</Button>
                  }
                      <Navbar.Divider/>
                      <AnchorButton
                          target={"_blank"}
                          minimal={true}
                          // icon={"git-repo"}
                          href="https://git.geniyz.site/?p=bux.git"
                      >
                          <img src={"https://git.geniyz.site/theme/git-logo.png"}
                               alt={".git"}
                               style={{height: "1.3em"}}
                          />
                      </AnchorButton>
              </Navbar.Group>

          </Navbar>
  );
}

export default Header;
