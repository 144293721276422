import "@blueprintjs/table/lib/css/table.css";

import {Card, CardList, Icon} from "@blueprintjs/core";


import React from "react";

export default function Pays ({
                        categories = [],
                        selectedCategory = "",
                        chCaterory = (category)=>{},
                   }) {
    return(
        <Card
            compact={true}
            className={"categories-list"}
        >
            <div className="category-pos">
            <h5>Категории</h5>

            <CardList>
                <Card
                    selected={"" == (selectedCategory||"")}
                    interactive={true}
                    style={{justifyContent: "space-between"}}
                    onClick={()=>{chCaterory("")}}
                >
                    <span>все</span>
                    <Icon icon={"chevron-right"} />
                </Card>
                {
                    (categories||[]).map(k=>(
                        <Card
                            selected={k == selectedCategory}
                            interactive={true}
                            style={{justifyContent: "space-between"}}
                            onClick={()=>{chCaterory(k)}}
                        >
                            <span>{k}</span>
                            <Icon icon={"chevron-right"} />
                        </Card>
                    ))
                }
            </CardList>
            </div>
        </Card>
    )
}


