import "./style.css"

import {
    AnchorButton,
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    InputGroup,
    Switch, TagInput, TextArea, Checkbox
} from "@blueprintjs/core";


import React, {useEffect, useId, useRef, useState} from "react";
import ProductSuggestionator from "./ProductSuggestionator";
import CategorySuggestionator from "./CategorySuggestionator";
import KeywordsSuggestionator from "./KeywordsSuggestionator";

export default function Editor ({
                     paymentProduct = {}, // as
                     onSave = (ppData)=>{},
                     onClose = ()=>{},
                  }) {

    let [paymentProductData, setPaymentProductData] = useState(paymentProduct)
    function setPayProduct(newState={}){
        paymentProductData = Object.assign({}, paymentProductData, newState)

        if ((paymentProductData.qty || 0) <= 0) {
            paymentProductData.qty = 1
        }
        if( !(newState.price) ) { // если меняется не цена
            paymentProductData.price = (paymentProductData.sum || 0) / paymentProductData.qty // пересчитать её
            if(paymentProductData.price==0) paymentProductData.price = null // если оная оказалась нулевой, не отображать ноль
        }
        if( !(newState.sum) ) {
            paymentProductData.sum = (paymentProductData.price || 0) * paymentProductData.qty
            if(paymentProductData.sum==0) paymentProductData.sum = null
        }

        setPaymentProductData(paymentProductData)
    }

    let [isOpen, setIsOpen] = useState(true)

    const [keywords, setKeywords] = useState((paymentProduct.keywords||[]).map((it)=>(it.code))  )
    useEffect(() => {
        setPayProduct({keywords: keywords.map(it=>({code: it}))})
    }, [keywords]);

    const id = useId()

    const diaRef = useRef(null)

    const handleClose = ()=>{
        onClose && onClose()
        setIsOpen(false)
        try {
            diaRef && diaRef.current && diaRef.current.remove()
        }catch (_) {
            // ну не получилось и не получилось
        }
    }

    return(<Dialog
            title={paymentProductData.id ? "Редактирование «" + paymentProductData.product.code + "»" : "Добавление позиции" }
            style={{width: "clamp(30%, 456px, 90%)"}}
            className="dialog-style"
            isOpen={isOpen}
            onClose={handleClose}
            canEscapeKeyClose={true}
            canOutsideClickClose={false}
            enforceFocus={true}
            shouldReturnFocusOnClose={true}
            containerRef={diaRef}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    // const formData = new FormData(e.target);
                    // formData.set("keywords", paymentProductData.keywords.join("; "));
                    onSave(paymentProductData);
                    handleClose();
                }}
            >

                <DialogBody>

                    <FormGroup
                        label="Номенклатура"
                        labelFor={"product-" + id}
                        className="w1"
                    >
                        {/*
                        <InputGroup id={"product-" + id} type="text" name="product" value={paymentProductData.product.code}
                                    readOnly={!!(paymentProductData.id)}
                                    onChange={(e) => {
                                        setPayProduct({product:{code: e.target.value}})
                                    }}
                                    required/>
                        */}
                        <ProductSuggestionator
                            currentItem={paymentProductData.product}
                            onChange={(itm)=>{ setPayProduct({product: itm}) }}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Сумма"
                        labelFor={"sum-" + id}
                        className="w⅓"
                    >
                        <InputGroup id={"sum-" + id} type="text" name="sum" value={paymentProductData.sum}
                                    onChange={(e) => {
                                        setPayProduct({sum: e.target.value})
                                    }}
                                    required/>
                    </FormGroup>

                    <FormGroup
                        label="Кол-во"
                        labelFor={"qty-" + id}
                        className="w⅓"
                    >
                        <InputGroup id={"qty-" + id} type="text" name="qty" value={paymentProductData.qty}
                                    onChange={(e) => {
                                        setPayProduct({qty: e.target.value})
                                    }}
                                    required/>
                    </FormGroup>

                    <FormGroup
                        label="Цена"
                        labelFor={"price-" + id}
                        className="w⅓"
                    >
                        <InputGroup id={"price-" + id} type="text" name="price" value={paymentProductData.price}
                                    onChange={(e) => {
                                        setPayProduct({price: e.target.value})
                                    }}
                                    />
                    </FormGroup>

                    <FormGroup
                        label="Категория"
                        labelFor={"category-" + id}
                        className="w⅓"
                    >
                        {/*
                        <InputGroup id={"category-" + id} type="text" name="category" value={paymentProductData.category.code}
                                    onChange={(e) => {
                                        setPayProduct({category: {code: e.target.value}})
                                    }}
                                    required/>
                        */}

                        <CategorySuggestionator
                            currentItem={paymentProductData.category}
                            onChange={(itm)=>{ setPayProduct({category: itm}) }}
                        />
                    </FormGroup>

                    <FormGroup
                        label="Ключевые слова"
                        labelFor={"keywords-" + id}
                        className="w⅔"
                    >
                        <KeywordsSuggestionator
                            currentItems={ keywords.map(it=>({code: it, title: it})) }
                            onChange={(itms)=>{ setKeywords(itms.map(it=>it.code)) }}
                        />
                    </FormGroup>

                    <Checkbox id={"asDef-" + id}
                              label={"Сделать такие ключевые слова и категорют дефолтными для номенклатуры"}
                              name="asDef"
                              onChange={(e) => {
                                      setPayProduct({asDef: e.target.checked})
                              }}
                    />

                    <FormGroup
                        label="Описание"
                        labelFor={"description-" + id}
                        className="w1"
                    >
                        <TextArea id={"description-" + id} name="description" fill={true}
                                  onChange={(e) => {
                                      setPayProduct({description: e.target.value})
                                  }}
                                  autoResize={true}>{paymentProductData.description}</TextArea>
                    </FormGroup>

                </DialogBody>

                <DialogFooter actions={<>
                    <Button type="reset" text="Отмена" onClick={handleClose}/>
                    <Button type="submit" text="Сохранить" intent="primary"/>
                </>}
                />
            </form>
        </Dialog>
    )
}

