import "@blueprintjs/datetime2/lib/css/blueprint-datetime2.css";
import "@blueprintjs/select/lib/css/blueprint-select.css";

import {DateInput3, TimePrecision} from "@blueprintjs/datetime2";

import React, {useCallback, useEffect, useState} from "react";

const formatDateLocal = (date, withTime=false) => {
    try {
        const dt = (date || new Date())
        const d = ( (typeof dt == "string") ? new Date(dt) : dt )
        return ('0'+d.getDate()).slice(-2)
            + "."
            + ('0'+(d.getMonth() + 1)).slice(-2)
            + "." + d.getFullYear()
            +(withTime
                ? (
                    " "
                    +('0'+d.getHours()).slice(-2)
                    +":"
                    +('0'+d.getMinutes()).slice(-2)
                    )
                : ""
             )
    }catch (e) {
        return null
    }
}

const formatDate = (date, withTime=false) => {
    try {
        const a1 = (date || new Date()).toISOString().split(/[T\s]/);
        const a2 = a1[0].split("-");
        return a2[2] + "." + a2[1] + "." + a2[0]+(withTime ? " "+a1[1] : "")
    }catch (e) {
        return null
    }
}

const parseDate = (str, withTime=false) => {

    if(!str) return new Date();

    const a1 = str.split(/[T\s]/)

    console.log("parseDate: ", str, a1, withTime)

    if(str.indexOf("-")+1) {
        const a2 = a1[0].split("-");
        return new Date(a2[0] + "-" + a2[1] + "-" + a2[2] + (withTime ? "T"+a1[1] : ""))
    }else{
        const a2 = a1[0].split(".");
        return new Date(a2[2] + "-" + a2[1] + "-" + a2[0] + (withTime ? "T"+a1[1] : ""))
    }
}

function DateInput({
    value = "",
    withTime=false,
    onChange = (str, dat)=>{}
                   }) {

    let [selectedDate, setSelectedDate] = useState( (typeof value == "string") ? value : formatDate(value, withTime) );

    useEffect(() => {
        onChange && onChange(selectedDate, parseDate(selectedDate, withTime))
    }, [selectedDate]);

  return (<>
          <DateInput3
              value={selectedDate}
              dateFnsFormat={"dd.MM.yyyy"+(withTime ? " HH:mm" : "")}
              onChange={(str)=>{
                  console.log("onChange: ", str)
                  setSelectedDate(str)
              }}
              closeOnSelection={true}
              fill={false}
              timePrecision={ withTime ? TimePrecision.MINUTE : undefined }
              timePickerProps={
                  withTime
                      ? { fill: false, selectAllOnFocus: true, useAmPm: false }
                      : undefined
              }
          />
  </>);
}

export {formatDate, parseDate, formatDateLocal}
export default DateInput;
